// Copyright (C) 2017-2023 Smart code 203358507

const React = require("react");
const PropTypes = require("prop-types");
const classnames = require("classnames");
const { default: Icon } = require("@stremio/stremio-icons/react");
const { t } = require("i18next");
const {
	Button,
	Image,
	useProfile,
	platform,
	useToast,
	Popup,
	useBinaryState,
	useStreamingServer,
} = require("stremio/common");
const { useServices } = require("stremio/services");
const { useRouteFocused } = require("stremio-router");
const StreamPlaceholder = require("./StreamPlaceholder");
const styles = require("./styles");

const Stream = ({
	className,
	videoId,
	videoReleased,
	addonName,
	name,
	description,
	thumbnail,
	progress,
	deepLinks,
	...props
}) => {
	const profile = useProfile();
	const toast = useToast();
	const { core } = useServices();
	const routeFocused = useRouteFocused();
	const streamingServer = useStreamingServer();

	const [menuOpen, , closeMenu, toggleMenu] = useBinaryState(false);

	const popupLabelOnMouseUp = React.useCallback((event) => {
		if (!event.nativeEvent.togglePopupPrevented) {
			if (event.nativeEvent.ctrlKey || event.nativeEvent.button === 2) {
				event.preventDefault();
				toggleMenu();
			}
		}
	}, []);
	const popupLabelOnContextMenu = React.useCallback(
		(event) => {
			if (
				!event.nativeEvent.togglePopupPrevented &&
				!event.nativeEvent.ctrlKey
			) {
				event.preventDefault();
			}
		},
		[toggleMenu]
	);
	const popupLabelOnLongPress = React.useCallback(
		(event) => {
			if (
				event.nativeEvent.pointerType !== "mouse" &&
				!event.nativeEvent.togglePopupPrevented
			) {
				toggleMenu();
			}
		},
		[toggleMenu]
	);
	const popupMenuOnPointerDown = React.useCallback((event) => {
		event.nativeEvent.togglePopupPrevented = true;
	}, []);
	const popupMenuOnContextMenu = React.useCallback((event) => {
		event.nativeEvent.togglePopupPrevented = true;
	}, []);
	const popupMenuOnClick = React.useCallback((event) => {
		event.nativeEvent.togglePopupPrevented = true;
	}, []);
	const popupMenuOnKeyDown = React.useCallback((event) => {
		event.nativeEvent.buttonClickPrevented = true;
	}, []);

	const href = React.useMemo(() => {
		return deepLinks
			? deepLinks.externalPlayer
				? deepLinks.externalPlayer.web
					? deepLinks.externalPlayer.web
					: deepLinks.externalPlayer.openPlayer
					? deepLinks.externalPlayer.openPlayer[platform.name]
						? deepLinks.externalPlayer.openPlayer[platform.name]
						: deepLinks.externalPlayer.playlist
					: deepLinks.player
				: deepLinks.player
			: null;
	}, [deepLinks]);

	const download = React.useMemo(() => {
		return href === deepLinks?.externalPlayer?.playlist
			? deepLinks.externalPlayer.fileName
			: null;
	}, [href, deepLinks]);

	const target = React.useMemo(() => {
		return href === deepLinks?.externalPlayer?.web ? "_blank" : null;
	}, [href, deepLinks]);

	const streamLink = React.useMemo(() => {
		return deepLinks?.externalPlayer?.download;
	}, [deepLinks]);

	const markVideoAsWatched = React.useCallback(() => {
		if (typeof videoId === "string") {
			core.transport.dispatch({
				action: "MetaDetails",
				args: {
					action: "MarkVideoAsWatched",
					args: [{ id: videoId, released: videoReleased }, true],
				},
			});
		}
	}, [videoId, videoReleased]);

	const onClick = React.useCallback(
		(event) => {
			event.preventDefault();
			if (
				profile.settings.playerType !== null &&
				deepLinks &&
				deepLinks.externalPlayer
			) {
				markVideoAsWatched();

				if (deepLinks.externalPlayer.download) {
					let lc =
						"infuse://x-callback-url/play?url=" +
						encodeURIComponent(deepLinks.externalPlayer.download);

					window.location.href = lc;
				} else if (deepLinks.externalPlayer.streaming) {
					let lc =
						"infuse://x-callback-url/play?url=" +
						encodeURIComponent(deepLinks.externalPlayer.streaming);

					window.location.href = lc;
				} else if (deepLinks.player) {
					// Handle the player URL if needed
					console.log("Player URL:", deepLinks.player);
					// You might want to do something with this URL
				} else {
					console.error("No suitable URL found in deepLinks:", deepLinks);
					toast.show({
						type: "error",
						title: "Unable to open stream",
						timeout: 4000,
					});
					return;
				}

				toast.show({
					type: "success",
					title: "Stream opened in Infuse",
					timeout: 4000,
				});
			} else {
				console.error("Invalid configuration for external player");
				toast.show({
					type: "error",
					title: "Unable to open stream",
					timeout: 4000,
				});
			}

			if (typeof props.onClick === "function") {
				props.onClick(event);
			}
		},
		[props.onClick, profile.settings, markVideoAsWatched, deepLinks, toast]
	);

	const copyStreamLink = React.useCallback(
		(event) => {
			event.preventDefault();
			closeMenu();
			if (streamLink) {
				navigator.clipboard
					.writeText(streamLink)
					.then(() => {
						toast.show({
							type: "success",
							title: t("PLAYER_COPY_STREAM_SUCCESS"),
							timeout: 4000,
						});
					})
					.catch(() => {
						toast.show({
							type: "error",
							title: t("PLAYER_COPY_STREAM_ERROR"),
							timeout: 4000,
						});
					});
			}
		},
		[streamLink]
	);

	const renderThumbnailFallback = React.useCallback(
		() => (
			<Icon className={styles["placeholder-icon"]} name={"ic_broken_link"} />
		),
		[]
	);

	const renderLabel = React.useMemo(
		() =>
			function renderLabel({ className, children, ...props }) {
				return (
					<Button
						className={classnames(className, styles["stream-container"])}
						title={addonName}
						href={href}
						target={target}
						download={download}
						onClick={onClick}
						{...props}
					>
						<div className={styles["info-container"]}>
							{typeof thumbnail === "string" && thumbnail.length > 0 ? (
								<div
									className={styles["thumbnail-container"]}
									title={name || addonName}
								>
									<Image
										className={styles["thumbnail"]}
										src={thumbnail}
										alt={" "}
										renderFallback={renderThumbnailFallback}
									/>
								</div>
							) : (
								<div
									className={styles["addon-name-container"]}
									title={name || addonName}
								>
									<div className={styles["addon-name"]}>
										{name || addonName}
									</div>
								</div>
							)}
							{progress !== null && !isNaN(progress) && progress > 0 ? (
								<div className={styles["progress-bar-container"]}>
									<div
										className={styles["progress-bar"]}
										style={{ width: `${progress}%` }}
									/>
									<div className={styles["progress-bar-background"]} />
								</div>
							) : null}
						</div>
						<div
							className={styles["description-container"]}
							title={description}
						>
							{description}
						</div>
						<Icon className={styles["icon"]} name={"play"} />
						{children}
					</Button>
				);
			},
		[
			thumbnail,
			progress,
			addonName,
			name,
			description,
			href,
			target,
			download,
			onClick,
		]
	);

	const renderMenu = React.useMemo(
		() =>
			function renderMenu() {
				return (
					<div
						className={styles["context-menu-content"]}
						onPointerDown={popupMenuOnPointerDown}
						onContextMenu={popupMenuOnContextMenu}
						onClick={popupMenuOnClick}
						onKeyDown={popupMenuOnKeyDown}
					>
						<Button
							className={styles["context-menu-option-container"]}
							title={t("CTX_PLAY")}
						>
							<div className={styles["context-menu-option-label"]}>
								{t("CTX_PLAY")}
							</div>
						</Button>
						{streamLink && (
							<Button
								className={styles["context-menu-option-container"]}
								title={t("CTX_COPY_STREAM_LINK")}
								onClick={copyStreamLink}
							>
								<div className={styles["context-menu-option-label"]}>
									{t("CTX_COPY_STREAM_LINK")}
								</div>
							</Button>
						)}
					</div>
				);
			},
		[copyStreamLink, onClick]
	);

	React.useEffect(() => {
		if (!routeFocused) {
			closeMenu();
		}
	}, [routeFocused]);

	return (
		<Popup
			className={className}
			onMouseUp={popupLabelOnMouseUp}
			onLongPress={popupLabelOnLongPress}
			onContextMenu={popupLabelOnContextMenu}
			open={menuOpen}
			onCloseRequest={closeMenu}
			renderLabel={renderLabel}
			renderMenu={renderMenu}
		/>
	);
};

Stream.Placeholder = StreamPlaceholder;

Stream.propTypes = {
	className: PropTypes.string,
	videoId: PropTypes.string,
	videoReleased: PropTypes.instanceOf(Date),
	addonName: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	thumbnail: PropTypes.string,
	progress: PropTypes.number,
	deepLinks: PropTypes.shape({
		player: PropTypes.string,
		externalPlayer: PropTypes.shape({
			download: PropTypes.string,
			streaming: PropTypes.string,
			playlist: PropTypes.string,
			fileName: PropTypes.string,
			web: PropTypes.string,
			openPlayer: PropTypes.shape({
				ios: PropTypes.string,
				android: PropTypes.string,
				windows: PropTypes.string,
				macos: PropTypes.string,
				linux: PropTypes.string,
			}),
			infuse: PropTypes.shape({
				ios: PropTypes.string,
				android: PropTypes.string,
				windows: PropTypes.string,
				macos: PropTypes.string,
				linux: PropTypes.string,
			}),
		}),
	}),
	onClick: PropTypes.func,
};

module.exports = Stream;
